export const GET_FILE = 'GET_FILE'
export const SET_FILE = 'SET_FILE'
export const LOAD_FILE = 'LOAD_FILE'

export const SET_PRESET_LOADING = 'SET_PRESET_LOADING'
export const SET_PRESET_QUEUE = 'SET_PRESET_QUEUE'
export const SET_PRESET = 'SET_PRESET'
export const GET_PRESET = 'GET_PRESET'
export const LOAD_PRESET = 'LOAD_PRESET'

export const SET_VOLUME = 'SET_VOLUME'
export const SET_PLAY = 'SET_PLAY'
export const SET_STOP = 'SET_STOP'

export const SET_SPEED = 'SET_SPEED'
export const SET_DEPTH = 'SET_DEPTH'

export const SET_LOADING = 'SET_LOADING'
export const GET_LOADING = 'SET_LOADING'

export const CLEAR_TRACKS = 'CLEAR_TRACKS'
