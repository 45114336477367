import axios from 'axios';
import db from './database';

// const backend = "http://192.168.1.58:8000/"
// const backend = "http://127.0.0.1:8000/"
// const backend = "https://stevaidis.mywire.org:887/"
const backend = "https://soundback.vaidis.eu/soundfiles/"

// CONSOLE LOG --------------------------------------
function X(msg, obj) {
    const enable = true
    const object = obj ? ": " + JSON.stringify(obj) : ""
    return enable
        ? console.log('%c%s', 'color: white; background: #222222; font-size: 12px;', "api.js " + msg + object)
        : null
}

export const api = {
    get: async function get(value) {
        if (value.title === "Off") {
            X("OFF", value.title)
            return value.title
        } else {
            return db.soundfiles.get(value.title)
                .then(function (result) {
                    if (result === undefined) {
                        X("FETCH", value.title)
                        const url = backend + value.file;
                        return axios.get(url, {
                            method: 'get',
                            responseType: 'blob',
                            timeout: 10000,
                        }).then(response => {
                            const blob = new Blob([response.data], { type: "audio/ogg" });
                            const blobURL = URL.createObjectURL(blob);
                            X("blobURL", blobURL)
                            db.soundfiles.put({ title: value.title, file: blobURL })
                            return value.title
                        }).catch(error => {
                            throw new Error(
                                `${error.response.statusText} (${error.response.status})`
                            );
                        });
                    } else {
                        X("LOCAL", value.title)
                        return value.title
                    }
                })
        }
    }
}


// export const api = {
//     get: async function get(value) {
//         X("value: ", value)
//         const url = backend + value.file;
//         X("url: ", url)
//         return axios.get(url, {
//             method: 'get',
//             responseType: 'blob',
//             timeout: 10000,
//         })
//             .then(response => {
//                 const blob = new Blob([response.data], { type: "audio/ogg" });
//                 const blobURL = URL.createObjectURL(blob);
//                 X("blobURL", blobURL)
//                 db.soundfiles.put({ title: value.title, file: blobURL })
//                 return value.title
//             })
//             .catch(error => {
//                 throw new Error(
//                     `${error.response.statusText} (${error.response.status})`
//                 );
//             });
//         // }
//     },
// }
