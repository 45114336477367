import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Slider from '@material-ui/core/Slider';
import { setVolume } from './actions';

//
// loaded by Track component
//

function Volume({ dispatchSetVolume, preset, props }) {

    return (
        <Fragment>
            <div className="volume">
                {preset.volume}
            </div>
            <div className="slider">
                <Slider
                    min={0}
                    max={100}
                    step={1}
                    orientation="vertical"
                    onChange={(e, value) => dispatchSetVolume(value)}
                    value={preset.volume}
                    defaultValue={props.volume}
                />
            </div>
        </Fragment>

    );
}

const mapStateToProps = (state, ownProps) => ({
    preset: state.preset[ownProps.id],
    props: ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchSetVolume: (value) => dispatch(setVolume(ownProps.id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Volume);

