import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import Link from '@material-ui/core/Link';
// import FavoriteIcon from '@material-ui/icons/Favorite';

function Footer(props) {
    return (
        <div>
            <div className="warning">
            <div className="warningWrapper">
                {/* <DialogContentText className="warningText"> */}
                    <WarningIcon className="warningIcon" />
                    <div className="warningText">
                        Deep Sleep works better with external speakers and subwoofer
                    </div>
                {/* </DialogContentText> */}
                </div>
            </div>
            {/* <div>
                <a className="badge" target="_blank" href='https://play.google.com/store/apps/details?id=com.stevaidis.deepsleepdemo&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </div> */}
            {/* <div className="badgeText">
                Right now only the demo is available. The full version wil be ready in a few days.
            </div> */}
        </div>
    )
}

export default Footer;