export const soundList = {
    'Off' : '',
    'Birds Nightinale' : 'birds_nightinale.ogg',
    'Birds Owl' : 'birds_owl.ogg',
    'Birds Tropical' : 'birds_tropical.ogg',
    'Birds Seagulls' : 'brids_seagulls.ogg',
    'Crickets Calm' : 'crickets_calm.ogg',
    'Crickets Light' : 'crickets_light.ogg',
    'Crickets Moderate' : 'crickets_moderate.ogg',
    'Crickets Strong' : 'crickets_strong.ogg',
    'Device aircontition' : 'device_aircontition.ogg',
    'Device Dryer' : 'device_dryer.ogg',
    'Device Vacum' : 'device_vacum.ogg',
    'Device Washing' : 'device_washing.ogg',
    'Engine Airplane' : 'engine_aeroplane.ogg',
    'Engine Boat' : 'engine_boat.ogg',
    'Engine Jeep' : 'engine_jeep.ogg',
    'Engine Submarine' : 'engine_submarine.ogg',
    'Engine Yatch' : 'engine_yatch.ogg',
    'nature crickets_2' : 'nature_crickets_2.ogg',
    'Nature Fire' : 'nature_fire.ogg',
    'Nature Thunders' : 'nature_thunders.ogg',
    'Nature Wind' : 'nature_wind.ogg',
    'Noise Black' : 'noise_black.ogg',
    'Noise Brown' : 'noise_brown.ogg',
    'Noise Pink' : 'noise_pink.ogg',
    'Noise White' : 'noise_white.ogg',
    'People Bar' : 'people_bar.ogg',
    'People Cafe' : 'people_cafe.ogg',
    'People Conference' : 'people_conference.ogg',
    'People Harbor' : 'people_harbor.ogg',
    'People Restaurant' : 'people_restaurant.ogg',
    'Rain Gentle' : 'rain_gentle.ogg',
    'Rain Light' : 'rain_light.ogg',
    'Rain Moderate' : 'rain_medium.ogg',
    'Rain Strong' : 'rain_strong.ogg',
    'River Gentle' : 'river_gentle.ogg',
    'River Light' : 'river_light.ogg',
    'River Moderate' : 'river_medium.ogg',
    'River Strong' : 'river_strong.ogg',
    'Train Calm' : 'train_calm.ogg',
    'Train Light' : 'train_light.ogg',
    'Train Moderate' : 'train_moderate.ogg',
    'Train Strong' : 'train_strong.ogg',
    'Underwater Gentle' : 'underwater_gentle.ogg',
    'Underwater Light' : 'underwater_light.ogg',
    'Underwater Moderate' : 'underwater_moderate.ogg',
    'Underwater Sonar' : 'underwater_sonar.ogg',
    'Underwater Strong' : 'underwater_strong.ogg',
    'Underwater Whale' : 'underwater_whale.ogg',
    'Waves Gentle' : 'waves_gentle.ogg',
    'Waves Light' : 'waves_light.ogg',
    'Waves Moderate' : 'waves_medium.ogg',
    'Waves Strong' : 'waves_strong.ogg',
}
