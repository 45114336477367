import React from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { setFile, setPresetLoading, clearTracks, setPresetQueue } from './actions';
import { soundList } from './soundList'
import { presetList } from './presetsList'

// CONSOLE LOG --------------------------------------
function X(msg, obj) {
    const enable = false
    const object = obj ? ": " + JSON.stringify(obj) : ""
    return enable
        ? console.log('%c%s', 'color: black; background: #a7b5e8; font-size: 12px;', " setPreset.js " + msg + object)
        : null
}

function SetPreset({
    props,
    state,
    loadingPreset,
    dispatchSetFile,
    dispatchSetLoadingPreset,
    dispatchSetPresetQueue,
    dispatchClearTracks }) {

    const [presetName, setPresetName] = React.useState(false);
    const [stateLoadingPreset, setStateLoadingPreset] = React.useState(false);

    React.useEffect(() => {
        setStateLoadingPreset(loadingPreset)
    }, [state]);


    const queueSetFile = (id, value) => {
        if (stateLoadingPreset === false) {
            X("queueSetFile() FREE", id)
            dispatchSetLoadingPreset(true)
        } else {
            X("queueSetFile() WAIT", id)
        }
    }

    const handleChange = (event, value) => {
        const newPreset = value.props.value
        setPresetName(presetName => value.props.value);
        if (newPreset === "Mute_All") {
            dispatchClearTracks()
        } else {
            dispatchSetPresetQueue(presetList[newPreset])
        };
    }

    return (
        <div className="presets">
            <div className="select">
                <FormControl>
                    <Select
                        autoWidth={true}
                        onChange={handleChange}
                        defaultValue=""
                        displayEmpty={true}
                    >
                        <MenuItem value="" disabled >Select Preset...</MenuItem>
                        {Object.keys(presetList).map((key, i) => {
                            return (<MenuItem key={i} value={String(key)}> {String(key)} </MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </div>
        </div >
    );
}

const mapStateToProps = (state, ownProps) => ({
    props: ownProps,
    state: state,
    loadingPreset: state.loadingPreset,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchSetFile: (value) => dispatch(setFile(ownProps.id, value)),
    dispatchClearTracks: () => dispatch(clearTracks()),
    dispatchSetPresetLoading: (value) => dispatch(setPresetLoading(value)),
    dispatchSetPresetQueue: (value) => dispatch(setPresetQueue(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPreset);
