import React from 'react';
import { connect } from 'react-redux';
import { setPlay, setStop } from './actions';

import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

//
// loaded by Track component
//

function Controls({ preset ,dispatchSetPlay, dispatchSetStop, props }) {

    return (
        <div className="controls">
            <div className="button play">
                <IconButton
                    onClick={() => {dispatchSetPlay(props.id)}}
                    disabled={preset.play}
                    aria-label="delete"
                    color="primary"
                >
                    <PlayArrowIcon />
                </IconButton>
            </div>

            <div className="button stop">
                <IconButton
                    onClick={() => {dispatchSetStop(props.id)}}
                    disabled={!preset.play}
                    aria-label="delete"
                    color="secondary"
                >
                    <StopIcon />
                </IconButton>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    preset: state.preset[ownProps.id],
    props: ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchSetPlay: () => dispatch(setPlay(ownProps.id)),
    dispatchSetStop: () => dispatch(setStop(ownProps.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);