import { fork, call, put, take, all, takeEvery, takeLatest } from 'redux-saga/effects';
import { setPreset, getFile, setFile } from './actions';
import { 
    SET_FILE, 
    GET_FILE, 
    LOAD_FILE, 
    SET_LOADING,
    SET_PRESET_LOADING,
    GET_LOADING, 
    LOAD_PRESET, 
    SET_PRESET, 
    SET_PRESET_QUEUE,
    CLEAR_TRACKS} from './constants'
import {loadFile } from './actions'

import {api} from './api';


function X(msg, obj) {
    const enable = true
    const object = obj ? ": " + JSON.stringify(obj) : ""
    return enable
        ? console.log('%c%s', 'color: #444444; background:#fc90d0; font-size: 12px;', "sagas.js " + msg + object)
        : null
}

// ----------------------------------------------------- WORKERS
function* setPresetWorker({ value }) {
    X("setPresetWorker",);
    yield put({ type: SET_PRESET_LOADING, value: true })
    yield put({ type: CLEAR_TRACKS})
    try {
        X("setPresetWorker player 0", value[0]);
        yield put({ type: SET_LOADING, id: 0, value: true })
        const response0 = yield call(api.get, value[0]);
        yield put({ type: LOAD_FILE, value: {id: 0, title: value[0].title, file: response0, volume: value[0].volume, speed: value[0].speed, depth: value[0].depth} })
        yield put({ type: SET_LOADING, id: 0, value: false })

        X("setPresetWorker player 1", value[1]);
        yield put({ type: SET_LOADING, id: 1, value: true })
        const response1 = yield call(api.get, value[1]);
        yield put({ type: LOAD_FILE, value: {id: 1, title: value[1].title, file: response1, volume: value[1].volume, speed: value[1].speed, depth: value[1].depth} })
        yield put({ type: SET_LOADING, id: 1, value: false })

        X("setPresetWorker player 2", value[2]);
        yield put({ type: SET_LOADING, id: 2, value: true })
        const response2 = yield call(api.get, value[2]);
        yield put({ type: LOAD_FILE, value: {id: 2, title: value[2].title, file: response2, volume: value[2].volume, speed: value[2].speed, depth: value[2].depth} })
        yield put({ type: SET_LOADING, id: 2, value: false })

        X("setPresetWorker player 3", value[3]);
        yield put({ type: SET_LOADING, id: 3, value: true })
        const response3 = yield call(api.get, value[3]);
        yield put({ type: LOAD_FILE, value: {id: 3, title: value[3].title, file: response3, volume: value[3].volume, speed: value[3].speed, depth: value[3].depth} })
        yield put({ type: SET_LOADING, id: 3, value: false })

        X("setPresetWorker player 4", value[4]);
        yield put({ type: SET_LOADING, id: 4, value: true })
        const response4 = yield call(api.get, value[4]);
        yield put({ type: LOAD_FILE, value: {id: 4, title: value[4].title, file: response4, volume: value[4].volume, speed: value[4].speed, depth: value[4].depth} })
        yield put({ type: SET_LOADING, id: 4, value: false })

        // const response = yield call(api.get, value);
        // X("setPresetWorker response", response);
    } catch (error) {
        X("setPresetWorker error", error);
    } finally {
        X("setPresetWorker finally");
        yield put({ type: SET_PRESET_LOADING, value: false })
    }
}

function* setFileWorker({ value }) {
    // X("setFileWorker",);
    yield put({ type: SET_LOADING, id: value.id, value: true })
    try {
        X("setFileWorker value", value);
        const response = yield call(api.get, value);
        // X("setFileWorker response", response);
        yield put({ type: LOAD_FILE, value: {id: value.id, title: value.title, file: response} })
    } catch (error) {
        X("setFileWorker error", error);
    } finally {
        X("setFileWorker finally");
        yield put({ type: SET_LOADING, value: false })
    }
}

// ----------------------------------------------------- WATCHERS
function* setPresetWatcher() {
    while (true) {
        X("setPresetWatcher before");
        const value = yield take(SET_PRESET_QUEUE);
        yield fork(setPresetWorker, value);
        X("setPresetWatcher after");
    }
}

function* setFileWatcher() {
    while (true) {
        // X("setFileWatcher before");
        const value = yield take(SET_FILE);
        yield fork(setFileWorker, value);
        // X("setFileWatcher after");
    }
}

// ----------------------------------------------------- COMBINE
export default function* sagas() {
    yield all([
        setFileWatcher(),
        setPresetWatcher(),
    ]);
}
