import React from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import { presetList } from './presetsList'
import { setPreset } from './actions';

function Presets({ dispatchSetPreset }) {

    const [presetName, setPresetName] = React.useState(false);

    const handleChange = (event, value) => {

        setPresetName( presetName => value.props.value );

        const presetName = event.target.value;
        const presetNew = Object.keys(presetList)
            .filter(key => key === presetName)
            .map((key, i) => presetList[key]);

            // console.log("Presets handleChange() event", event.target.value)
            // console.log("Presets handleChange() value", value.props.value)
            // console.log("Presets handleChange() presetName", presetName)
            // console.log("Presets handleChange() presetNew[0]", presetNew[0])

        dispatchSetPreset(presetNew[0]);
    };

    return (
        <div className="presets">
                <div className="select">
                    <FormControl>
                        <Select
                            autoWidth={true}
                            onChange={handleChange}
                            defaultValue=""
                            displayEmpty={true}
                        >
                            <MenuItem value="" disabled >Select Preset...</MenuItem>
                            {Object.keys(presetList).map((key, i) => {
                                return (<MenuItem key={i} value={String(key)}> {String(key)} </MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </div>
        </div >
    );
}

const mapStateToProps = (state) => ({
    state: state,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchSetPreset: (preset) => dispatch(setPreset(preset)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Presets);
