import React from 'react';
import { Knob } from "react-rotary-knob";
import * as skins from 'react-rotary-knob-skin-pack';
import { connect } from 'react-redux';
import { setSpeed, setDepth } from './actions';

//
// loaded by Track component
//

function Tremolo({props, preset, dispatchSetSpeed, dispatchSetDepth }) {
    
    // console.log("Tremolo", props)
    return (
        <div className="buttons">
            <div className="knob">
                <Knob
                    style={{ 
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 40,
                    }}
                    skin={skins.s12}
                    min={0}
                    max={0.64}
                    clampMin={0}
                    clampMax={270}
                    rotateDegrees={-135}
                    unlockDistance={10}
                    onChange={ e => dispatchSetSpeed(e) }
                    defaultValue={props.speed}
                    value={preset.speed}
                />
                <label>Speed</label>
            </div>
            <div className="knob">
                <Knob
                    style={{ 
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 40,
                    }}
                    skin={skins.s11}
                    min={0}
                    max={1}
                    clampMin={0}
                    clampMax={270}
                    rotateDegrees={-135}
                    unlockDistance={10}
                    onChange={ e => dispatchSetDepth(e) }
                    defaultValue={props.depth}
                    value={preset.depth}
                />
                <label>Depth</label>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    player: state.player[ownProps.id],
    preset: state.preset[ownProps.id],
    props: ownProps,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchSetSpeed: (value) => dispatch(setSpeed(ownProps.id, value)),
    dispatchSetDepth: (value) => dispatch(setDepth(ownProps.id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tremolo);