import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import Link from '@material-ui/core/Link';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import WarningIcon from '@material-ui/icons/Warning';
// import LaunchIcon from '@material-ui/icons/Launch';
// import HomeIcon from '@material-ui/icons/Home';

function About(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const preventDefault = event => event.preventDefault();

    return (
        <div className="about">

            <Button className="aboutButton" onClick={handleClickOpen}>?</Button>
            <Dialog className="dialog" onClose={handleClose} open={open}>

                <DialogTitle className="aboutTitle">
                    About Sounds [v4.0]
                </DialogTitle>

                <DialogContentText className="aboutText">
                    Deepwave.me is a multi track audio player with amplitude variation, logarithmic faders and sound samples ready loaded for helping you to sleep
                </DialogContentText>

                {/* <DialogContentText className="aboutText">
                    <WarningIcon className="aboutIcon" />
                    Please rate this app for what is promised to do, not for what you wanted to be.
                </DialogContentText>

                <DialogContentText className="aboutText">
                    <FavoriteIcon className="aboutIcon" />
                    If you like this app you can get the full version for less than a beer
                </DialogContentText> */}

                <Button onClick={handleClose}>Close</Button>

            </Dialog>
        </div>
    );
}

export default About;