import { 
    SET_FILE, 
    GET_FILE,
    LOAD_FILE,
    SET_DEPTH,
    SET_SPEED,
    SET_VOLUME,
    SET_PLAY,
    SET_STOP,
    SET_PRESET,
    GET_PRESET,
    LOAD_PRESET,
    SET_PRESET_LOADING,
    SET_LOADING,
    GET_LOADING,
    CLEAR_TRACKS,
    SET_PRESET_QUEUE,
} from './constants'

export function setLoading(value) {
    return {
        type: SET_LOADING,
        value: value,
    }
};

export function clearTracks(value) {
    return {
        type: CLEAR_TRACKS,
        value: value,
    }
};

export function setPresetQueue(value) {
    console.log("action.js SET_PRESET_QUEUE value:", value)
    return {
        type: SET_PRESET_QUEUE,
        value: value,
    }
};

export function setPresetLoading(value) {
    console.log("action.js SET_RESET_LOADING value:", value)
    return {
        type: SET_PRESET_LOADING,
        value: value,
    }
};


export function getLoading(value) {
    return {
        type: GET_LOADING,
        value: value,
    }
};

export function loadPreset(value) {
    return {
        type: LOAD_PRESET,
        value: value
    }
}

export function setPreset(value) {
    return {
        type: SET_PRESET,
        value: value
    }
}

export function getPreset(value) {
    return {
        type: GET_PRESET,
        value: value
    }
}

export function setVolume(id, value) {
    return {
        type: SET_VOLUME,
        id: id,
        value: value,
    }
}

export function setPlay(id) {
    return {
        type: SET_PLAY,
        id: id,
    }
}

export function setStop(id) {
    return {
        type: SET_STOP,
        id: id,
    }
}

export function setSpeed(id, value) {
    return {
        type: SET_SPEED,
        id: id,
        value: value,
    }
}

export function setDepth(id, value) {
    return {
        type: SET_DEPTH,
        id: id,
        value: value,
    }
}

export function setFile(id, value) {
    console.log("action.js SET_FILE id, file, title:", id, value)
    return {
        type: SET_FILE,
        id: id,
        value: value,
    }
}

export function getFile(value) {
    console.log("action.js GET_FILE value:", value)
    return {
        type: GET_FILE,
        value: value,
    }
}

export function loadFile(value) {
    console.log("action.js LOAD_FILE value:", value)
    return {
        type: LOAD_FILE,
        value: value,
    }
}
