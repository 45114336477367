import React from 'react';
import { connect } from 'react-redux';

class Canvas extends React.Component {

    constructor(props, context) {
        super(props, context);

        CanvasRenderingContext2D.prototype.wavy = function (from, to, frequency, amplitude, step, negative) {
            // console.log("prototype", from, to, frequency, amplitude, step, negative)

            var cx = 0,
                cy = 0,
                fx = from.x,
                fy = from.y,
                tx = to.x,
                ty = to.y,
                i = 0,
                waveOffsetLength = 0,
                ang = Math.atan2(ty - fy, tx - fx),
                distance = Math.sqrt((fx - tx) * (fx - tx) + (fy - ty) * (fy - ty)),
                a = amplitude * (!negative ? 1 : -1),
                f = Math.PI * frequency;

            for (i; i <= distance; i += step) {
                waveOffsetLength = Math.sin((i / distance) * f) * a;
                cx = from.x + Math.cos(ang) * i + Math.cos(ang - Math.PI / 2) * waveOffsetLength;
                cy = from.y + Math.sin(ang) * i + Math.sin(ang - Math.PI / 2) * waveOffsetLength;
                i > 0 ? this.lineTo(cx, cy) : this.moveTo(cx, cy);
            }
        }
    }

    componentDidMount() {
        // console.log("Canvas.js componentDidMount() ", this.props.state.preset[0]);
        this.updateCanvas();
        return false;
    }

    componentDidUpdate() {
        // console.log("Canvas.js shouldComponentUpdate() 1 this.props", this.props.state.preset[0]);
        this.updateCanvas();
        return false;
    }

    updateCanvas() {
        var t0_volume = 130 - this.props.state.preset[0].volume
        var t0_speed = this.props.state.preset[0].speed * 45
        var t0_depth = this.props.state.preset[0].depth * 25

        var t1_volume = 130 - this.props.state.preset[1].volume
        var t1_speed = this.props.state.preset[1].speed * 45
        var t1_depth = this.props.state.preset[1].depth * 25

        var t2_volume = 130 - this.props.state.preset[2].volume
        var t2_speed = this.props.state.preset[2].speed * 45
        var t2_depth = this.props.state.preset[2].depth * 25

        var t3_volume = 130 - this.props.state.preset[3].volume
        var t3_speed = this.props.state.preset[3].speed * 45
        var t3_depth = this.props.state.preset[3].depth * 25

        var t4_volume = 130 - this.props.state.preset[4].volume
        var t4_speed = this.props.state.preset[4].speed * 45
        var t4_depth = this.props.state.preset[4].depth * 25

        // console.log("Canvas.js updateCanvas() t0_volume", t0_volume);
        // console.log("Canvas.js updateCanvas() t5_speed", t1_speed);
        // console.log("Canvas.js updateCanvas() t5_speed", t1_speed);

        var p = function (x, y) {
            return { x: x, y: y };
        };

        const trans = 0.7
        const linewidth = 2
        const ctx = this.refs.canvas.getContext('2d');

        // REFRESH
        ctx.clearRect(0, 0, 350, 300)

        // PURPLE
        if (this.props.state.preset[4].volume > 1 && this.props.state.preset[4].play) {
            ctx.strokeStyle = 'rgba(200,100,255,' + trans + ')';
            ctx.moveTo(0, 0);
            ctx.lineWidth = linewidth;
            ctx.beginPath();
            ctx.wavy(p(0, t4_volume), p(350, t4_volume), t4_speed, t4_depth, 1);
            ctx.stroke();
        }
        // BLUE
        if (this.props.state.preset[3].volume > 1 && this.props.state.preset[3].play) {
            ctx.strokeStyle = 'rgba(100,150,255,' + trans + ')';
            ctx.moveTo(0, 0);
            ctx.lineWidth = linewidth;
            ctx.beginPath();
            ctx.wavy(p(0, t3_volume), p(350, t3_volume), t3_speed, t3_depth, 1);
            ctx.stroke();
        }
        // GREEN
        if (this.props.state.preset[2].volume > 1 && this.props.state.preset[2].play) {
            ctx.strokeStyle = 'rgba(150,250,0,' + trans + ')';
            ctx.moveTo(0, 0);
            ctx.lineWidth = linewidth;
            ctx.beginPath();
            ctx.wavy(p(0, t2_volume), p(350, t2_volume), t2_speed, t2_depth, 1);
            ctx.stroke();
        }
        // ORANGE
        if (this.props.state.preset[1].volume > 1 && this.props.state.preset[1].play) {
            ctx.strokeStyle = 'rgba(255,150,0,' + trans + ')';
            ctx.moveTo(0, 0);
            ctx.lineWidth = linewidth;
            ctx.beginPath();
            ctx.wavy(p(0, t1_volume), p(350, t1_volume), t1_speed, t1_depth, 1);
            ctx.stroke();
        }
        // RED
        if (this.props.state.preset[0].volume > 1 && this.props.state.preset[0].play) {
            ctx.strokeStyle = 'rgba(255,50,0,' + trans + ')';
            ctx.moveTo(0, 0);
            ctx.lineWidth = linewidth;
            ctx.beginPath();
            ctx.wavy(p(0, t0_volume), p(350, t0_volume), t0_speed, t0_depth, 1);
            ctx.stroke();
        }

    }

    render() {
        // console.log("Canvas.js render() ", this.props.state.preset[0].volume);
        return (
            // <canvas ref="canvas" />
            <canvas ref="canvas" width={350} height={150} />
        )
    }
}

const mapStateToProps = (state) => ({
        state: state,
    });

export default connect(mapStateToProps, null)(Canvas);
// export default Canvas
