import React from 'react';
import Controls from "./Controls"
import Volume from "./Volume"
import Tremolo from "./Tremolo"
import { connect } from 'react-redux';
import SetFile from './SetFile'

// -------------------------------------------------------
// Track component loaded by Tracks component in App.js
// -------------------------------------------------------

function Track({props, loading}) {
    return (
        <div className="track">
            <div className={`color ${loading ? " loading" + props.id : ""}`}></div>
            <SetFile id={props.id} label={props.label} />
            <Volume id={props.id} volume={props.volume} />
            <Controls id={props.id} play={props.play} />
            <Tremolo id={props.id} speed={props.speed} depth={props.depth} />
            <div className={`color ${loading ? " loading" + props.id : ""}`}></div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    loading: state.preset[ownProps.id].loading,
    props: ownProps,
});

export default connect(mapStateToProps, null)(Track);
