export const initialState = {
    loadingPreset: false,
    presetQueue: {},
    preset: {
        0: {
            loading: false,
            play: false,
            volume: 0,
            label: "",
            speed: 0.0,
            depth: 0.0,
        },
        1: {
            loading: false,
            play: false,
            volume: 0,
            label: "",
            speed: 0.0,
            depth: 0.0,
        },
        2: {
            loading: false,
            play: false,
            volume: 0,
            label: "",
            speed: 0.0,
            depth: 0.0,
        },
        3: {
            loading: false,
            play: false,
            volume: 0,
            label: "",
            speed: 0.0,
            depth: 0.0,
        },
        4: {
            loading: false,
            play: false,
            volume: 0,
            label: "",
            speed: 0.0,
            depth: 0.0,
        },
    },
    player: {
        0: {
            tremolo: {},
            sound: {},
        },
        1: {
            tremolo: {},
            sound: {},
        },
        2: {
            tremolo: {},
            sound: {},
        },
        3: {
            tremolo: {},
            sound: {},
        },
        4: {
            tremolo: {},
            sound: {},
        },
    },
    files: {},
}