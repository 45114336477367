const birds_nightinale = 'birds_nightinale.ogg'
const birds_owl = 'birds_owl.ogg'
const birds_tropical = 'birds_tropical.ogg'
const brids_seagulls = 'brids_seagulls.ogg'
const crickets_calm = 'crickets_calm.ogg'
const crickets_light = 'crickets_light.ogg'
const crickets_moderate = 'crickets_moderate.ogg'
const crickets_strong = 'crickets_strong.ogg'
const device_aircontition = 'device_aircontition.ogg'
const device_dryer = 'device_dryer.ogg'
const device_vacum = 'device_vacum.ogg'
const device_washing = 'device_washing.ogg'
const engine_aeroplane = 'engine_aeroplane.ogg'
const engine_boat = 'engine_boat.ogg'
const engine_jeep = 'engine_jeep.ogg'
const engine_submarine = 'engine_submarine.ogg'
const engine_yatch = 'engine_yatch.ogg'
const nature_fire = 'nature_fire.ogg'
const nature_thunders = 'nature_thunders.ogg'
const nature_wind = 'nature_wind.ogg'
const noise_black = 'noise_black.ogg'
const noise_brown = 'noise_brown.ogg'
const noise_pink = 'noise_pink.ogg'
const noise_white = 'noise_white.ogg'
const people_bar = 'people_bar.ogg'
const people_cafe = 'people_cafe.ogg'
const people_conference = 'people_conference.ogg'
const people_harbor = 'people_harbor.ogg'
const people_restaurant = 'people_restaurant.ogg'
const rain_gentle = 'rain_gentle.ogg'
const rain_light = 'rain_light.ogg'
const rain_moderate = 'rain_medium.ogg'
const rain_strong = 'rain_strong.ogg'
const river_gentle = 'river_gentle.ogg'
const river_light = 'river_light.ogg'
const river_moderate = 'river_medium.ogg'
const river_strong = 'river_strong.ogg'
const train_calm = 'train_calm.ogg'
const train_light = 'train_light.ogg'
const train_moderate = 'train_moderate.ogg'
const train_strong = 'train_strong.ogg'
const underwater_gentle = 'underwater_gentle.ogg'
const underwater_light = 'underwater_light.ogg'
const underwater_moderate = 'underwater_moderate.ogg'
const underwater_sonar = 'underwater_sonar.ogg'
const underwater_strong = 'underwater_strong.ogg'
const underwater_whale = 'underwater_whale.ogg'
const waves_gentle = 'waves_gentle.ogg'
const waves_light = 'waves_light.ogg'
const waves_moderate = 'waves_medium.ogg'
const waves_strong = 'waves_strong.ogg'

export const presetList = {
    "RESET": {
        0: {title: "Off",                volume:  0, file: '',               speed: 0.00, depth: 0.00},
        1: {title: "Off",                volume:  0, file: '',               speed: 0.00, depth: 0.00},
        2: {title: "Off",                volume:  0, file: '',               speed: 0.00, depth: 0.00},
        3: {title: "Off",                volume:  0, file: '',               speed: 0.00, depth: 0.00},
        4: {title: "Off",                volume:  0, file: '',               speed: 0.00, depth: 0.00},
    },
    "Nature: Forest Nightinale": {
        0: {title: "Nature Fire",        volume: 74, file: nature_fire,      speed: 0.14, depth: 0.31},
        1: {title: "Rain Light",         volume: 86, file: rain_light,       speed: 0.07, depth: 0.26},
        2: {title: "Birds Nightinale",   volume: 75, file: birds_nightinale, speed: 0.06, depth: 0.55},
        3: {title: "Waves Gentle",       volume: 96, file: waves_gentle,     speed: 0.12, depth: 0.23},
        4: {title: "Off",                volume:  0, file: noise_brown,      speed: 0.00, depth: 0.00},
    },
    "Nature: Rainy beach": {
        0: {title: "Rain Gentle",        volume: 87, file: rain_gentle,      speed: 0.05, depth: 0.22},
        1: {title: "Nature Wind",        volume: 60, file: nature_wind,      speed: 0.09, depth: 0.32},
        2: {title: "Birds Seagulls",     volume: 72, file: brids_seagulls,   speed: 0.14, depth: 0.10},
        3: {title: "Noise Black",        volume: 87, file: noise_black,      speed: 0.21, depth: 0.16},
        4: {title: "Waves Strong",       volume:100, file: waves_strong,     speed: 0.09, depth: 0.02},
    },
    "Nature: Summer beach": {
        0: {title: "Waves Gentle",       volume: 98, file: waves_gentle,       speed: 0.05, depth: 0.09},
        1: {title: "Birds Owl",          volume: 66, file: birds_owl,          speed: 0.09, depth: 0.41},
        2: {title: "River Gentle",       volume: 79, file: river_gentle,       speed: 0.17, depth: 0.34},
        3: {title: "Crickets Light",     volume: 90, file: crickets_light,     speed: 0.14, depth: 0.16},
        4: {title: "Crickets Moderate",  volume: 65, file: crickets_moderate,  speed: 0.19, depth: 0.26},
    },
    "Nature: Open Sea": {
        0: {title: "Birds Seagulls",     volume: 72, file: brids_seagulls,     speed: 0.03, depth: 0.35},
        1: {title: "Waves Strong",       volume: 99, file: waves_strong,       speed: 0.17, depth: 0.16},
        2: {title: "Rain Strong",        volume: 71, file: rain_strong,        speed: 0.05, depth: 0.21},
        3: {title: "Noise Black",        volume: 85, file: noise_black,        speed: 0.14, depth: 0.62},
        4: {title: "Off",                volume:  0, file: '',                 speed: 0.00, depth: 0.00},
    }, 
    "Nature: Tropical Beach": {
        0: {title: "Birds Tropical",     volume: 74, file: birds_tropical,     speed: 0.24, depth: 0.13},
        1: {title: "Nature Wind",        volume: 52, file: nature_wind,        speed: 0.22, depth: 0.21},
        2: {title: "Waves Light",        volume: 97, file: waves_light,        speed: 0.09, depth: 0.23},
        3: {title: "Noise Brown",        volume: 66, file: noise_brown,        speed: 0.13, depth: 0.13},
        4: {title: "Off",                volume:  0, file: '',                 speed: 0.00, depth: 0.00},
    },
    "Nature: River Boat": {
        0: {title: "River Moderate",     volume: 84, file: river_moderate,     speed: 0.08, depth: 0.09},
        1: {title: "Crickets Moderate",  volume: 94, file: crickets_moderate,  speed: 0.28, depth: 0.13},
        2: {title: "Engine Boat",        volume: 72, file: engine_boat,        speed: 0.18, depth: 0.19},
        3: {title: "Noise Black",        volume: 87, file: noise_black,        speed: 0.14, depth: 0.20},
        4: {title: "Noise Brown",        volume: 71, file: noise_brown,        speed: 0.18, depth: 0.52},
    },
    "Nature: Free Camp": {
        0: {title: "Crickets Calm",      volume: 94, file: crickets_calm,       speed: 0.15, depth: 0.15},
        1: {title: "Crickets Strong",    volume: 76, file: crickets_strong,     speed: 0.11, depth: 0.20},
        2: {title: "Waves Gentle",       volume: 93, file: waves_gentle,        speed: 0.06, depth: 0.33},
        3: {title: "Nature Fire",        volume: 81, file: nature_fire,         speed: 0.07, depth: 0.31},
        4: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
    },
    "Nature: Stormy Sea": {
        0: {title: "Rain Moderate",      volume: 74, file: rain_moderate,       speed: 0.06, depth: 0.17},
        1: {title: "Nature Thunders",    volume: 82, file: nature_thunders,     speed: 0.22, depth: 0.21},
        2: {title: "Waves Moderate",     volume:100, file: waves_moderate,      speed: 0.09, depth: 0.30},
        3: {title: "Birds Seagulls",     volume: 79, file: brids_seagulls,      speed: 0.10, depth: 0.20},
        4: {title: "Noise Black",        volume: 94, file: noise_black,         speed: 0.16, depth: 0.17},
    },
    "Nature: Thunder Owl": {
        0: {title: "Nature Thunders",    volume: 88, file: nature_thunders,     speed: 0.22, depth: 0.18},
        1: {title: "Birds Owl",          volume: 75, file: birds_owl,           speed: 0.17, depth: 0.05},
        2: {title: "Nature Wind",        volume: 72, file: nature_wind,         speed: 0.24, depth: 0.19},
        3: {title: "River Light",        volume: 82, file: river_moderate,      speed: 0.35, depth: 0.09},
        4: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
    },
    "Train: Winter Trip": {
        0: {title: "Train Calm",         volume: 92, file: train_calm,          speed: 0.21, depth: 0.08},
        1: {title: "Nature Wind",        volume: 74, file: nature_wind,         speed: 0.14, depth: 0.47},
        2: {title: "Rain Strong",        volume: 75, file: rain_strong,         speed: 0.10, depth: 0.40},
        3: {title: "Engine Airplane",    volume: 78, file: engine_aeroplane,    speed: 0.09, depth: 0.19},
        4: {title: "Noise Black",        volume: 81, file: noise_black,         speed: 0.11, depth: 0.22},
    },
    "Train: Summer Trip": {
        0: {title: "Train Light",        volume:100, file: train_light,         speed: 0.19, depth: 0.21},
        1: {title: "Birds Tropical",     volume: 68, file: birds_tropical,      speed: 0.14, depth: 0.47},
        2: {title: "Rain Strong",        volume: 79, file: rain_strong,         speed: 0.10, depth: 0.40},
        3: {title: "Engine Airplane",    volume: 72, file: engine_aeroplane,    speed: 0.09, depth: 0.19},
        4: {title: "Noise Black",        volume: 81, file: noise_black,         speed: 0.07, depth: 0.12},
    },
    "Train: Stormy Trip": {
        0: {title: "Train Moderate",     volume: 82, file: train_moderate,      speed: 0.21, depth: 0.08},
        1: {title: "Nature Thunders",    volume: 89, file: nature_thunders,     speed: 0.22, depth: 0.21},
        2: {title: "Nature Wind",        volume: 82, file: nature_wind,         speed: 0.14, depth: 0.47},
        3: {title: "Rain Strong",        volume: 78, file: rain_strong,         speed: 0.10, depth: 0.40},
        4: {title: "Engine Airplane",    volume: 78, file: engine_aeroplane,    speed: 0.09, depth: 0.19},
    },
    "Underwater: Whales Song": {
        0: {title: "Underwater Gentle",  volume: 99, file: underwater_gentle,   speed: 0.06, depth: 0.37},
        1: {title: "Underwater Moderate",volume: 82, file: underwater_moderate, speed: 0.13, depth: 0.75},
        2: {title: "Underwater Whale",   volume: 78, file: underwater_whale,    speed: 0.17, depth: 0.38},
        3: {title: "Noise Black",        volume: 92, file: noise_black,         speed: 0.09, depth: 0.08},
        4: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
    }, 
    "Underwater: Submarine Room": {
        0: {title: "Underwater Moderate",volume: 89, file: underwater_moderate, speed: 0.32, depth: 0.15},
        1: {title: "Engine Submarine",   volume: 79, file: engine_submarine,    speed: 0.30, depth: 0.42},
        2: {title: "Underwater Sonar",   volume: 70, file: underwater_sonar,    speed: 0.18, depth: 0.12},
        3: {title: "Noise Black",        volume: 65, file: noise_black,         speed: 0.11, depth: 0.48},
        4: {title: "Noise Brown",        volume: 88, file: noise_brown,         speed: 0.21, depth: 0.57},
    },
    "People: Rainy Bar": {
        0: {title: "People Bar",         volume:100, file: people_bar,          speed: 0.14, depth: 0.26},
        1: {title: "Rain Light",         volume: 92, file: rain_light,          speed: 0.06, depth: 0.20},
        2: {title: "Nature Thunders",    volume: 84, file: nature_thunders,     speed: 0.12, depth: 0.16},
        3: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
        4: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
    },
    "People: River Coffe": {
        0: {title: "People Cafe",        volume: 78, file: people_cafe,         speed: 0.13, depth: 0.11},
        1: {title: "Crickets Moderate",  volume: 99, file: crickets_moderate,   speed: 0.11, depth: 0.17},
        2: {title: "River Moderate",     volume: 79, file: river_moderate,      speed: 0.22, depth: 0.11},
        3: {title: "Waves Moderate",     volume: 82, file: waves_moderate,      speed: 0.19, depth: 0.23},
        4: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
    },
    "People: Conference at Train": {
        0: {title: "People Conference",  volume: 68, file: people_conference,   speed: 0.17, depth: 0.11},
        1: {title: "Noise Brown",        volume: 94, file: noise_brown,         speed: 0.12, depth: 0.15},
        2: {title: "Train Calm",         volume: 92, file: train_calm,          speed: 0.16, depth: 0.26},
        3: {title: "Train Light",        volume: 83, file: train_moderate,      speed: 0.21, depth: 0.46},
        4: {title: "Engine Jeep",        volume: 69, file: engine_jeep,         speed: 0.25, depth: 0.35},
    },
    "Devices: Hair Dryer": {
        0: {title: "Device Dryer",       volume: 89, file: device_dryer,       speed: 0.09, depth: 0.26},
        1: {title: "Engine Boat",        volume: 75, file: engine_boat,        speed: 0.56, depth: 0.51},
        2: {title: "Noise Pink",         volume: 59, file: noise_pink,         speed: 0.15, depth: 0.35},
        3: {title: "Off",                volume:  0, file: '',                 speed: 0.00, depth: 0.00},
        4: {title: "Off",                volume:  0, file: '',                 speed: 0.00, depth: 0.00},
    },      
    "Devices: Washing Machine": {
        0: {title: "Device Washing",     volume: 94, file: device_washing,      speed: 0.21, depth: 0.52},
        1: {title: "Noise Brown",        volume: 91, file: noise_brown,         speed: 0.29, depth: 0.71},
        2: {title: "Engine Submarine",   volume: 76, file: engine_submarine,    speed: 0.23, depth: 0.50},
        3: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
        4: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
    },
    "Devices: Vacum Noise": {
        0: {title: "Device Vacum",       volume: 98, file: device_vacum,        speed: 0.27, depth: 0.10},
        1: {title: "Noise White",        volume: 71, file: noise_white,         speed: 0.08, depth: 0.39},
        2: {title: "Noise Brown",        volume: 85, file: noise_brown,         speed: 0.12, depth: 0.15},
        3: {title: "Noise Black",        volume: 92, file: noise_black,         speed: 0.18, depth: 0.31},
        4: {title: "Off",                volume:  0, file: '',                  speed: 0.00, depth: 0.00},
    }, 
}
