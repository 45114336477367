import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { setFile } from './actions';
import { soundList } from './soundList'

// CONSOLE LOG --------------------------------------
function X(msg, obj) {
    const enable = true
    const object = obj ? ": " + JSON.stringify(obj) : ""
    return enable
        ? console.log('%c%s', 'color: black; background: #a7e8d5; font-size: 12px;', "setFile.js " + msg + object)
        : null
}

function SetFile({ props, label, dispatchSetFile }) {

    // X("label", label)
    const [currentSound, setCurrentSound] = React.useState('');

    const handleChange = (event, value) => {
        const file = soundList[event.target.value]
        console.log("handleChange() value", value)
        setCurrentSound(value.props.value)
        dispatchSetFile({ id: props.id, title: value.props.children[1], file: file })
    };

    useEffect(() => {
        // X("useEffect() label", soundList[label])
        setCurrentSound((label) => soundList[label])
    }, [label]);

    return (
        <div className="setFile">
            <FormControl>
                <Select
                    autoWidth={true}
                    onChange={handleChange}
                    defaultValue=""
                    displayEmpty={true}
                    value={label}
                >
                    <MenuItem value="" disabled >Select</MenuItem>
                    {Object.entries(soundList).map(([key, val], i) => {
                        return (<MenuItem key={i} value={key}> {String(key)} </MenuItem>)
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    props: ownProps,
    label: state.preset[ownProps.id].label,
    loading: state.preset[ownProps.id].loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatchSetFile: (value) => dispatch(setFile(ownProps.id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetFile);
