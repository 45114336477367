import Pizzicato from 'pizzicato';
import { initialState } from './initialState'
import { soundList } from './soundList'
import db from './database';

import {
    SET_FILE,
    GET_FILE,
    LOAD_FILE,
    SET_DEPTH,
    SET_SPEED,
    SET_VOLUME,
    SET_PLAY,
    SET_STOP,
    SET_PRESET,
    LOAD_PRESET,
    SET_LOADING,
    CLEAR_TRACKS,
    SET_PRESET_QUEUE,
    SET_PRESET_LOADING,
} from './constants'

function log(position) {
    var minp = 0;
    var maxp = 100;
    var minv = Math.log(100);
    var maxv = Math.log(100000);
    var scale = (maxv - minv) / (maxp - minp);
    const result = Math.exp(minv + scale * (position - minp));
    const output = result / 100000
    // console.log("scale", output)
    return output
}

// CONSOLE LOG --------------------------------------
function X(msg, obj) {
    const enable = true
    const object = obj ? ": " + JSON.stringify(obj) : ""
    return enable
        ? console.log('%c%s', 'color: #444444; background: #fccfcf; font-size: 12px;', "reducers.js " + msg + object)
        : null
}


Object.keys(initialState.preset).map((key, i) => {
    initialState.player[key].sound = new Pizzicato.Sound(
        {
            source: 'wave',
            options: {
                frequency: 1,
                volume: 0
            }
        }
    );
})


function reducer(state = initialState, action) {

    const trackPreset = state.preset[action.id]
    switch (action.type) {

        case SET_PRESET_LOADING:
            X("SET_PRESET_LOADING action.value", action);
            return {
                ...state, loadingPreset: action.value
            }

        case SET_LOADING:
            X("SET_LOADING action.value", action);
            return {
                ...state, preset: {
                    ...state.preset, [action.id]: {
                        ...trackPreset, loading: action.value
                    }
                }
            }

        case SET_PRESET_QUEUE:
            X("SET_PRESET_QUEUE action.value", action);
            return {
                ...state, presetQueue: action.value
            }

        case GET_FILE:
            X("reducers.js GET_FILE action.value", action.value);
            return state;

        case LOAD_PRESET:
            let newState = action.value
            Object.keys(newState).map((key, i) => {

                console.log("reducer.js SET_PRESET for", key, ":", newState[key].file)
                state.player[key].sound.stop()
                state.player[key].tremolo = new Pizzicato.Effects.Tremolo();
                state.player[key].sound = new Pizzicato.Sound(
                    {
                        source: 'file',
                        options: { path: newState[key].file, loop: true }
                    }, function () {
                        state.player[key].sound.addEffect(initialState.player[key].tremolo);
                        state.player[key].sound.volume = log(newState[key].volume)
                        state.player[key].tremolo.mix = 1
                        state.player[key].tremolo.speed = newState[key].speed
                        state.player[key].tremolo.depth = newState[key].depth
                        if (newState[key].play) {
                            state.player[key].sound.play()
                        } else {
                            state.player[key].sound.stop()
                        }
                        console.log("END", key);
                    });
            })
            console.log("END FINAL");
            return {
                ...state, preset: newState
            }

        case LOAD_FILE:
            X("LOAD_FILE action -----------------------", action.value)

            var set_play = false;
            var set_volume = 0;
            var set_speed = 0;
            var set_depth = 0;


            if (action.value.title !== 'Off') {
                set_play = true
            } else {
                set_play = false
            }

            if (action.value.volume !== undefined) {
                set_volume = action.value.volume
            } else {
                set_volume = Math.floor(Math.random() * (100 - 60 + 1) + 60);
            }

            if (action.value.speed !== undefined) {
                set_speed = action.value.speed
            } else {
                set_speed = Math.floor(Math.random() * (50 - 15 + 1) + 1) / 100;
            }

            if (action.value.depth !== undefined) {
                set_depth = action.value.depth
            } else {
                set_depth = Math.floor(Math.random() * (35 - 15 + 1) + 1) / 100;
            }

            if (action.value.title === "Off") {
                X("LOAD_FILE OFF id", action.value.id)
                state.player[action.value.id].sound.stop();
            } else {
                X("LOAD_FILE ON id", action.value)
                db.soundfiles.get(action.value.title).then(function (blob) {
                    X("LOAD_FILE db.soundfiles.get(blob)", action.value.id)
                    state.player[action.value.id].sound.stop();
                    state.player[action.value.id].tremolo = new Pizzicato.Effects.Tremolo();
                    state.player[action.value.id].sound = new Pizzicato.Sound({
                        source: 'file',
                        options: { path: blob.file, loop: true, volume: log(set_volume) }
                    }, function () {
                        X("LOAD_FILE sound loaded from indexedDB", action.value.id)
                        state.player[action.value.id].sound.addEffect(state.player[action.value.id].tremolo);
                        state.player[action.value.id].tremolo.mix = 1
                        state.player[action.value.id].tremolo.speed = set_speed
                        state.player[action.value.id].tremolo.depth = set_depth
                        state.player[action.value.id].sound.play();
                    });

                })
            }
            return {
                ...state,
                preset: {
                    loadingPreset: false,
                    ...state.preset,
                    [action.value.id]: {
                        // ...trackPreset,
                        // ...[action.value.id],
                        loading: false,
                        play: set_play,
                        volume: set_volume,
                        label: action.value.title,
                        speed: set_speed,
                        depth: set_depth,
                    }
                }
            }

        case CLEAR_TRACKS:
            X("CLEAR_TRACKS")
            Object.keys(initialState.preset).map((key, i) => {
                state.player[key].sound.stop();
            })

            return initialState

        case SET_VOLUME:
            state.player[action.id].sound.volume = log(action.value)
            return {
                ...state, preset: {
                    ...state.preset, [action.id]: {
                        ...trackPreset, volume: action.value
                    }
                }
            }

        case SET_PLAY:
            state.player[action.id].sound.play()
            return {
                ...state, preset: {
                    ...state.preset, [action.id]: {
                        ...trackPreset, play: true
                    }
                }
            }

        case SET_STOP:
            state.player[action.id].sound.stop()
            return {
                ...state, preset: {
                    ...state.preset, [action.id]: {
                        ...trackPreset, play: false
                    }
                }
            }

        case SET_SPEED:
            state.player[action.id].tremolo.speed = action.value
            return {
                ...state, preset: {
                    ...state.preset, [action.id]: {
                        ...trackPreset, speed: action.value
                    }
                }
            }

        case SET_DEPTH:
            state.player[action.id].tremolo.depth = action.value
            return {
                ...state, preset: {
                    ...state.preset, [action.id]: {
                        ...trackPreset, depth: action.value
                    }
                }
            }

        default:
            return state;
    }
};

export default reducer;