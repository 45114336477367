import React from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Presets from './Presets'
import SetPreset from './SetPreset'
import Track from './Track'
import About from './About'
import Logo from './Logo'
import Footer from './Footer'
import Canvas from './Canvas'
import { initialState } from './initialState'


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5544CC',
    },
    secondary: {
      light: '#0066ff',
      main: '#AA2244',
      contrastText: '#ffcc00',
    },
    type: 'dark',
    contrastThreshold: 3,
    tonalOffset: 0.2,
    slider: {
      trackColor: 'yellow',
      selectionColor: 'green',
    },
  },
});

const Tracks = (preset) => {
  // console.log("Tracks App.js preset", preset)
  return (
    <div id="tracks" className="tracks">
      {Object.keys(preset.preset).map((key, i) => {
        return (
          <div className={"track" + i} key={i}>
            <Track
              id={key}
              file={preset.preset[key].file}
              label={preset.preset[key].label}
              volume={preset.preset[key].volume}
              play={preset.preset[key].play}
              fx={preset.preset[key].fx}
              mix={preset.preset[key].mix}
              speed={preset.preset[key].speed}
              depth={preset.preset[key].depth}
            />
          </div>)
      }
      )}
    </div>
  );
}


function App() {

  const [preset] = React.useState(initialState.preset);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header>
          <Logo />
          <SetPreset />
          <About />
        </header>
        <Tracks preset={preset} />
        <Canvas />
        <div id="footer">
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
